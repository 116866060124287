<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="所属区域">
              <a-select v-model="queryParam.price_type" allowClear>
                <a-select-option v-for="item in priceTypeList" :value="item.value" :key="item.value" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="存箱地">
              <a-select v-model="queryParam.start_point_id" allowClear>
                <a-select-option v-for="item in areaList" :value="item.id" :key="item.id" >
                  {{ item.point_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="流向">
              <a-select v-model="queryParam.end_point_id" allowClear>
                <a-select-option v-for="item in areaList" :value="item.id" :key="item.id" >
                  {{ item.point_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="箱尺寸">
              <a-select v-model="queryParam.container_size" allowClear>
                <a-select-option v-for="item in containerSizeList" :value="item.value" :key="item.value" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '新建价格' : '编辑价格'"
      @cancel="handleClose"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form" ref="form">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="类型" prop="price_type">
              <a-select v-model="form.price_type" allowClear >
                <a-select-option v-for="item in priceTypeList" :value="item.value" :key="item.value" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="箱尺寸" prop="container_size">
              <a-select v-model="form.container_size" allowClear>
                <a-select-option v-for="item in containerSizeList" :value="item.value" :key="item.value" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="存箱地" prop="start_point_id">
              <a-select v-model="form.start_point_id" allowClear >
                <a-select-option v-for="item in areaList" :value="item.id" :key="item.id" >
                  {{ item.point_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="流向" prop="end_point_id">
              <a-select v-model="form.end_point_id" allowClear>
                <a-select-option v-for="item in areaList" :value="item.id" :key="item.id" >
                  {{ item.point_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税后价" prop="unit_price">
              <a-input-number style="width: 200px" v-model="form.unit_price" placeholder="请输入税后价" allowClear @change="syncNetPrice"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="净值" prop="net_price" v-if="form.price_type==='AR'">
              <a-input-number style="width: 200px" v-model="form.net_price" placeholder="请输入税后价" allowClear/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getAreaInit, listPriceInfos, saveOrModifyPrice, deletePrice } from '@/api/itms'
export default {
components: {
    STable
},
data() {
    return {
        queryParam: {},
        priceTypeList: [{ name: '收客户', value: 'AR' }, { name: '付车队', value: 'AP' }],
        containerSizeList: [{ name: '20', value: '20' }, { name: '40', value: '40' }, { name: '45', value: '45' }],
        columns: [
        {
            title: '#',
            scopedSlots: { customRender: 'serial' },
            align: 'center'
        },
        {
            title: '类型',
            dataIndex: 'price_type',
            customRender: (text) => {
            for (const item of this.priceTypeList) {
              if (text === item.value) {
                return item.name
              }
            }
            return ''
        }
        },
        {
            title: '存箱地',
            dataIndex: 'start_point_name'
        },
        {
            title: '流向',
            dataIndex: 'end_point_name'
        },
        {
            title: '箱尺寸',
            dataIndex: 'container_size'
        },
        {
            title: '税后价',
            dataIndex: 'unit_price'
        },
        {
            title: '净值',
            dataIndex: 'net_price'
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
        }
        ],
        loadData: parameter => {
        return listPriceInfos(Object.assign(parameter, this.queryParam)).then(res => {
            return res
        })
        },
        form: {
            id: null,
            price_type: null,
            container_size: null,
            start_point_id: null,
            end_point_id: null,
            unit_price: null,
            net_price: null
        },
        formVisible: false,
        confirmLoading: false,
        modalFlag: '',
        areaList: []
    }
},
mounted() {
    getAreaInit().then(res => {
        this.areaList = res
    })
},
methods: {
    handleCreate() {
        this.modalFlag = 'create'
        this.form = {
            id: null,
            price_type: null,
            container_size: null,
            start_point_id: null,
            end_point_id: null,
            unit_price: null,
            net_price: null
        }
        this.formVisible = true
        this.$nextTick(_ => {
        this.$refs.form.resetFields();
        })
    },
    handleClose() {
        this.formVisible = false
        this.form = {
            id: null,
            price_type: null,
            container_size: null,
            start_point_id: null,
            end_point_id: null,
            unit_price: null,
            net_price: null
        }
    },
    handleEdit(record) {
        this.modalFlag = 'update'
        this.formVisible = true
        this.form = record
    },
    handleDelete(record) {
        this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
            deletePrice({ id: record.id }).then(_ => {
            this.$notification['success']({
                message: '提示',
                description: '删除成功.'
            })
            this.$refs.table.refresh(true)
            })
        }
        })
    },
    handleSubmit() {
        this.$refs.form.validate(valid => {
        saveOrModifyPrice(this.form).then(_ => {
            this.$notification['success']({
                message: '提示',
                description: '保存或修改成功.'
            })
            this.formVisible = false
            this.$refs.table.refresh(true)
        })
        });
    },
    syncNetPrice() {
        if (this.form.price_type === 'AR' && this.form.unit_price) {
            this.form.net_price = (this.form.unit_price / 1.09).toFixed(2)
        }
    }
    }
}
</script>
